import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import parse from 'html-react-parser'
import { cn } from '@/lib/utils'
import { MainMenus } from './menu.constant'
import ArrowUp from '~/public/icons/top-menu/ArrowUp.svg'

const RenderHeaderMenu = ({ activeIndex, setActiveIndex }) => {
  // const currentRouter = router?.asPath
  // console.log({MainMenus})

  return (
    <>
      {MainMenus.map(parent => (
        <div key={parent.id}>
          <div
            className={'fixed bottom-0 left-0 right-0 top-[0] z-[100] bg-transparent'}
            onClick={() => setActiveIndex(0)}
            onMouseEnter={() => setActiveIndex(0)}
            style={{
              display: !!activeIndex ? 'block' : 'none'
            }}
          />
          <div className={'relative z-[110]'}>
            <div
              onMouseEnter={() => setActiveIndex(parent.id)}
              onClick={() => setActiveIndex(parent.id)}
              // className={cn(
              //   'relative cursor-pointer rounded-none px-[12px] h-[86px] text-[#0a225f] hover:text-[#100aff] xl-min:px-[34px] flex items-center justify-center',
              //   {
              //     'xl-min:!pl-0': parent.id === 1
              //   }
              // )}

              className={cn(
                'relative cursor-pointer rounded-none h-[86px] text-[#0a225f] hover:text-[#100aff] grid place-items-center'
              )}
            >
              <p
                className={cn('font-semibold m-0 text-center max-w-[90px] mx-auto ', {
                  'text-orange-primary': activeIndex === parent.id
                })}
              >
                {typeof parent.title === 'string' ? parse(parent.title) : parent.title}
              </p>
              {parent?.children?.length > 0 && activeIndex === parent.id && (
                <div className={'absolute bottom-[-14px] left-0 right-0 flex justify-center'}>
                  <Image priority src={ArrowUp} alt={'arrow-up'} />
                </div>
              )}
            </div>
          </div>

          {parent?.children?.length > 0 && (
            <div
              style={{
                display: activeIndex === parent.id ? 'block' : 'none'
              }}
              className={cn(
                'absolute left-0 right-0 top-[98px] z-[9999] mx-auto min-h-[150px] xl-min:min-w-[1020px] max-w-fit rounded-[15px] bg-white shadow-2xl',
                parent?.wrapperClass
              )}
            >
              <div className={'flex items-stretch justify-start gap-0'}>
                <div className='relative my-10 w-1/5 px-10'>
                  <p className={'mb-4 text-xl font-bold text-[#1E1E1E]'}>{parent?.label || ''}</p>
                  <div className={'[&_p]:text-base [&_p]:text-[#434F58]'}>
                    {!!parent?.content && parse(parent?.content)}
                  </div>
                  <div className={`absolute left-auto right-0 top-0 h-full border-r-[2px] border-[#3990EF]`} />
                </div>
                <div className={cn('m-10 w-4/5 grid grid-cols-3 gap-x-8 gap-y-8', parent?.childGridClass)}>
                  {parent?.children.map((child, index2) => (
                    <div className={'group'} key={child?.id || index2} onClick={() => setActiveIndex(0)}>
                      <Link href={child.url}>
                        <div className={'flex h-[74px] justify-start gap-5'}>
                          {!!child?.icon && (
                            <div className={'basis-[72px] text-center'}>
                              <Image src={child.icon} width={72} height={72} alt={child.label} />
                            </div>
                          )}
                          <div className={'h-[72px] basis-[calc(100%-92px)]'}>
                            <div className='flex items-center justify-start gap-1.5'>
                              <div className={'mb-0 font-bold text-[#1E1E1E] group-hover:text-blue-primary'}>
                                {child.label}
                              </div>
                              <div className={'invisible group-hover:visible'}>
                                {/*{iconList.rightArrow}*/} {'->'}
                              </div>
                            </div>
                            {!!child?.content && (
                              <div className={'text-base text-[#434F58]'}>{parse(child.content)}</div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default RenderHeaderMenu
