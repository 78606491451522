import { UrlType } from '@/v2/types'

const AUTH_ROUTES_CONSTANT = {
  RESET_PASSWORD: { href: '/reset-password', title: 'reset password', priority: 0.5 },
  LOGIN: { href: '/login', title: 'Login', priority: 0.5 },
  REGISTER: { href: '/register', title: 'Register', priority: 0.5 }
}

type AuthRoutesKeys = keyof typeof AUTH_ROUTES_CONSTANT

export const AUTH_ROUTES: Record<AuthRoutesKeys, UrlType> = AUTH_ROUTES_CONSTANT
