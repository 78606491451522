// @flow
import InfoPopOver from '@/components/subComponents/popover'
import { cn } from '@/lib/utils'
import React from 'react'

type Props = {
  title: string | React.ReactNode
  step?: number | string
  info?: {
    content?: string | React.ReactNode
  }
  className?: string
  contentClassName?: string
}

export default function StepTitleWithInfo({ step, title, info, className, contentClassName }: Props) {
  return (
    <>
      <div className={cn('flex items-center justify-between gap-0.5 md:gap-0 h-5', className)}>
        <p className={'text-[#0A9CFF] text-base font-bold basis-[58px]'}>{`Step ${step || 1}:`}</p>
        <div className={cn('flex !items-center justify-end gap-1 basis-[calc(100%-58px)]', contentClassName)}>
          <p className={'text-[14px] text-[#333] font-semibold'}>{title}</p>
          <div>
            {info?.content && (
              <InfoPopOver>
                <p className='text-sm [&_p]:font-medium [&_p]:text-black font-medium text-black text-center bg-[#EBF2F7] p-1 rounded-lg'>
                  {info?.content}
                </p>
              </InfoPopOver>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
