import { PUBLIC_MAIN_ROUTES } from '@/v2/constants/routes'

const Routes = Object.freeze({
  BOOK_NOW: '/book-storage-facility',
  BOOK_STORAGE_FACILITY: '/book-storage-facility',
  CHECK_PRICE: '/check-price',
  LOGIN: '/login',
  REGISTER: '/register',
  DASHBOARD: '/dashboard',
  MY_STORAGE: '/my-storage',
  CUSTOM_DOCUMENTS: '/order/custom-documents',
  TERMS: PUBLIC_MAIN_ROUTES.TERMS_AND_CONDITIONS,
  FAQ: PUBLIC_MAIN_ROUTES.LUGGAGE_AND_QUESTION,
  PROHIBITED_ITEMS: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP,
  DROP_OFF_LOCATION: PUBLIC_MAIN_ROUTES.DROP_OFF_LOCATION,
  LUGGAGE_TRACKING: PUBLIC_MAIN_ROUTES.LUGGAGE_TRACKING,
  SHIPPING_WEIGHT: PUBLIC_MAIN_ROUTES.SHIPPING_WEIGHT_AND_SIZES,
  SHIPPING_TIPS: PUBLIC_MAIN_ROUTES.SHIPPING_TIPS
})

export type RoutesType = typeof Routes

export { Routes }
