// Library Import
import React, { useState } from 'react'
import Image from 'next/image'
import { AppBar, Toolbar } from '@mui/material'

import useResponsive from '@/hooks/responsive'
import CustomLink from './includes/CustomLink'
import RenderHeaderMenu from './includes/RenderHeaderMenu'
import MobileMenus from './includes/mobile-menu/MobileMenus'

import MainLogo from '~/public/images/logos/shipandstorage.svg'
import headerStyles from './includes/Header.module.scss'
import { cn } from '@/lib/utils'
import UserMenu from '@/layouts/header/includes/UserMenu'
import { Routes } from '@/v1/constants/routes'
import * as actionTypes from '@/store/constants/actionTypes'
import { connect } from 'react-redux'
import AuthHeader from '@/layouts/header/AuthHeader'

function MainHeader(props) {
  const [activeIndex, setActiveIndex] = useState(0)

  const authenticated = props?.Auth_Reducer?.auth
  const { tabLandscape } = useResponsive()

  if (authenticated) {
    return <AuthHeader />
  }

  return (
    <AppBar
      color='inherit'
      position='sticky'
      className={cn(
        headerStyles['headerWr_nav'],
        headerStyles[`header-home-wr`],
        'h-[74px] xl:h-[98px] xl:!bg-[#ecf3fe]'
      )}
    >
      <div className={cn(headerStyles[`headerWr`])}>
        <div className={cn('relative z-[9999]', headerStyles['header_sticky_style'])}>
          <div className={'container relative'}>
            <Toolbar
              disableGutters
              className={cn('justify-between', headerStyles['headerMinHeight'], '!min-h-[74px] xl:!min-h-[98px]')}
              sx={{ justifyContent: tabLandscape ? 'space-between' : 'space-between' }}
            >
              <div className={cn(headerStyles[`logo-area-wr`])} onMouseEnter={() => setActiveIndex(0)}>
                <CustomLink href={'/'}>
                  <div className={'max-w-[120px] xl:max-w-[160px]'}>
                    <Image priority src={MainLogo} alt={'ship and storage'} width={163} height={74} />
                  </div>
                </CustomLink>
              </div>

              <div className={'flex justify-end items-center gap-20 xl:mt-3'}>
                <div className={'ml-auto hidden xl:flex items-center justify-end gap-9 2xl:gap-20'}>
                  <RenderHeaderMenu activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                </div>
                <div className={'flex justify-start'}>
                  <div className={'hidden xl:block'}>
                    <div onMouseEnter={() => setActiveIndex(0)}>
                      <UserMenu {...props} />
                    </div>
                  </div>
                  <div className={'xl:hidden'}>
                    <MobileMenus
                      dashboardRedirect={Routes.DASHBOARD}
                      authenticated={authenticated}
                      processSignOut={props?.onLogout}
                    />
                  </div>
                </div>
              </div>
            </Toolbar>
          </div>
        </div>
      </div>
    </AppBar>
  )
}

const mapStateToProps = state => {
  return {
    Auth_Reducer: state.authReducer
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch({ type: actionTypes.USER_LOGOUT })
})

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader)
