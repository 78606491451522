import { UrlType } from '@/v2/types'

export const LANDING_PAGES_ROUTES_CONSTANT = {
  /*OLD Routes*/
  BOX_STORAGE: { href: '/box-storage', title: 'box storage' },
  SKI_STORAGE: { href: '/ski-storage', title: 'ski storage' },
  LUGGAGE_BOX_STORAGE: { href: '/luggage-box-storage', title: 'luggage box storage' },

  /*New Routes*/
  SHIPPING_AND_STORAGE_SERVICES: { href: '/shipping-and-storage-services', title: 'shipping and storage services' },
  SHIP_TO_STORAGE: { href: '/ship-to-storage', title: 'ship to storage' },
  SHIP_TO_SELF_STORAGE: { href: '/ship-to-self-storage', title: 'ship to self storage' },
  SHIP_TO_SCHOOL: { href: '/ship-to-school', title: 'ship to school' },
  COLLEGE_STORAGE_SOLUTIONS: { href: '/college-storage-solutions', title: 'college storage solutions' },
  SHIP_TO_COLLEGE_DORM: { href: '/ship-to-college-dorm', title: 'ship to college dorm' },
  SHIP_AND_STORAGE_NEAR_ME: { href: '/ship-and-storage-near-me', title: 'ship and storage near me' },
  STORAGE_UNIT_WITH_SHIPPING: { href: '/storage-unit-with-shipping', title: 'storage unit with shipping' },
  SHIP_TO_STORAGE_FACILITY: { href: '/ship-to-storage-facility', title: 'ship to storage facility' },
  STORAGE_FACILITIES_THAT_SHIP: { href: '/storage-facilities-that-ship', title: 'storage facilities that ship' },
  STORAGE_AND_SHIPPING_NEAR_ME: { href: '/storage-and-shipping-near-me', title: 'storage and shipping near me' },
  SELF_STORAGE_SERVICES: { href: '/self-storage-services', title: 'self storage services' },
  STORAGE_AND_SHIPPING_SERVICES: { href: '/storage-and-shipping-services', title: 'storage and shipping services' },
  U_BOX_CONTAINER_STORAGE: { href: '/u-box-container-storage', title: 'u box container storage' },
  DORM_MOVING_STORAGE: { href: '/dorm-moving-storage', title: 'dorm moving storage' },
  COLLEGE_BOXES_STORAGE: { href: '/college-boxes-storage', title: 'college boxes storage' },
  COLLEGE_DORM_MOVING: { href: '/college-dorm-moving', title: 'college dorm moving' },
  COLLEGE_MOVING_SERVICES: { href: '/college-moving-services', title: 'college moving services' },
  COLLEGE_STORAGE_SOLUTION: { href: '/college-storage-solution', title: 'college storage solution' },
  COLLEGE_MOVING_COMPANY: { href: '/college-moving-company', title: 'college moving company' },
  COLLEGE_STUDENT_STORAGE_MOVING_SERVICES: {
    href: '/college-student-storage-moving-services',
    title: 'college student storage moving services'
  },
  COLLEGE_SHIPPING_STORAGE_MOVING_SERVICES: {
    href: '/college-shipping-storage-moving-services',
    title: 'college shipping storage moving services'
  },
  COLLEGE_MOVING_STUDENT_STORAGE_AND_SHIPPING: {
    href: '/college-moving-student-storage-and-shipping',
    title: 'college moving student storage & shipping'
  },
  DORM_ROOM_MOVERS_COLLEGE_MOVING_STORAGE: {
    href: '/dorm-room-movers-college-moving-storage',
    title: 'dorm room movers college moving storage'
  },
  COLLEGE_STORAGE_PRICE: { href: '/college-storage-price', title: 'college storage price' },
  FREE_STUDENT_STORAGE_SERVICES: { href: '/free-student-storage-services', title: 'free student storage services' },
  COLLAGE_STUDENT_MOVING: { href: '/collage-student-moving', title: 'collage student moving' },
  COLLAGE_STUDENT_SHIPPING: { href: '/collage-student-shipping', title: 'collage student shipping' },
  COLLEGE_STUDENT_STORAGE: { href: '/college-student-storage', title: 'college student storage' },
  FREE_SELF_STORAGE_UNITS: { href: '/free-self-storage-units', title: 'free self storage units' },
  FREE_PUBLIC_SELF_STORAGE: { href: '/free-public-self-storage', title: 'free public self storage' },
  FREE_STORAGE_PLACES: { href: '/free-storage-places', title: 'free storage places' },
  STORAGE_FOR_SUMMER: { href: '/storage-for-summer', title: 'storage for summer' },
  STORAGE_SCHOLARS_SERVICES: { href: '/storage-scholars-services', title: 'storage scholars services' },
  STORAGE_MOVING_AND_STUDENT_STORAGE: {
    href: '/storage-moving-and-student-storage',
    title: 'storage moving and student storage'
  },
  CHEAP_STORAGE_PLACES: { href: '/cheap-storage-places', title: 'cheap storage places' },
  STORAGE_FOR_WINTER: { href: '/storage-for-winter', title: 'storage for winter' },
  STORAGE_NEAR_ME: { href: '/storage-near-me', title: 'storage near me' },
  SELF_STORAGE_NEAR_ME: { href: '/self-storage-near-me', title: 'self storage near me' },
  STORAGE_SPACES_FOR_RENT: { href: '/storage-spaces-for-rent', title: 'storage spaces for rent' },
  STORAGE_PRICING_CALCULATOR: { href: '/storage-pricing-calculator', title: 'storage pricing calculator' },
  STORAGE_COST_ESTIMATE: { href: '/storage-cost-estimate', title: 'storage cost estimate' },
  HOME_STORAGE_SERVICES: { href: '/home-storage-services', title: 'home storage services' },
  STORAGE_UNIT_COST: { href: '/storage-unit-cost', title: 'storage unit cost' },
  STORAGE_AND_SHIPPING_BOXES: { href: '/storage-and-shipping-boxes', title: 'storage and shipping boxes' },
  SELF_STORAGE_AND_SHIPPING_SOLUTION: {
    href: '/self-storage-and-shipping-solution',
    title: 'self storage and shipping solution'
  },
  LONG_TERM_STORAGE_SOLUTIONS: { href: '/long-term-storage-solutions', title: 'long term storage solutions' },
  SHORT_TERM_STORAGE_SERVICES: { href: '/short-term-storage-services', title: 'short term storage services' },
  BUSINESS_STORAGE_SERVICES: { href: '/business-storage-services', title: 'business storage services' },
  PERSONAL_STORAGE_OPTIONS: { href: '/personal-storage-options', title: 'personal storage options' },
  DOCUMENT_STORAGE_SOLUTIONS: { href: '/document-storage-solutions', title: 'document storage solutions' },
  SELF_STORAGE_FACILITIES_NEAR_ME: {
    href: '/self-storage-facilities-near-me',
    title: 'self storage facilities near me'
  },
  FEDEX_SHIPPING_AND_STORAGE_SOLUTIONS: {
    href: '/fedex-shipping-and-storage-solutions',
    title: 'fedex shipping and storage solutions'
  },
  DHL_SHIPPING_AND_STORAGE_SERVICES: {
    href: '/dhl-shipping-and-storage-services',
    title: 'dhl shipping and storage services'
  },
  UPS_STORAGE_AND_SHIPPING_SOLUTIONS: {
    href: '/ups-storage-and-shipping-solutions',
    title: 'ups storage and shipping solutions'
  },
  SHIPPING_AND_STORAGE_SERVICES_NEAR_ME: {
    href: '/shipping-and-storage-services-near-me',
    title: 'shipping and storage services near me'
  },
  SELF_STORAGE_SOLUTION: { href: '/self-storage-solution', title: 'self storage solution' },
  MOVING_AND_STORAGE_DISCOUNTS: { href: '/moving-and-storage-discounts', title: 'moving and storage discounts' },
  INTERNATIONAL_SHIPPING_AND_STORAGE_SERVICES: {
    href: '/international-shipping-and-storage-services',
    title: 'international shipping and storage services'
  },
  LUGGAGE_SHIPPING_AND_STORAGE_SERVICES: {
    href: '/luggage-shipping-and-storage-services',
    title: 'luggage shipping and storage services'
  },
  RESIDENTIAL_AND_COMMERCIAL_STORAGE: {
    href: '/residential-and-commercial-storage',
    title: 'residential and commercial storage'
  },
  DORM_ROOM_MOVERS_AND_STORAGE: { href: '/dorm-room-movers-and-storage', title: 'dorm room movers and storage' },
  AFFORDABLE_SELF_STORAGE_SERVICES: {
    href: '/affordable-self-storage-services',
    title: 'affordable self storage services'
  },
  OFFICE_MOVING_AND_STORAGE: { href: '/office-moving-and-storage', title: 'office moving and storage' },
  OFFICE_DOCUMENT_STORAGE_SOLUTIONS: {
    href: '/office-document-storage-solutions',
    title: 'office document storage solutions'
  },
  DOCUMENT_STORAGE_SERVICES: { href: '/document-storage-services', title: 'document storage services' },
  LEGAL_DOCUMENT_STORAGE_FOR_LAW_FIRMS: {
    href: '/legal-document-storage-for-law-firms',
    title: 'legal document storage for law firms'
  },
  COLLEGE_LUGGAGE_STORAGE_SOLUTIONS: {
    href: '/college-luggage-storage-solutions',
    title: 'college luggage storage solutions'
  },
  STUDENT_STORAGE_AND_SHIPPING_SERVICES: {
    href: '/student-storage-and-shipping-services',
    title: 'student storage & shipping services'
  },
  SCHOOLS_SUMMER_STORAGE: { href: '/schools-summer-storage', title: 'schools summer storage' },
  SUMMER_STORAGE_FOR_COLLEGE_STUDENTS: {
    href: '/summer-storage-for-college-students',
    title: 'summer storage for college students'
  },
  COMMERCIAL_STORAGE_NEAR_ME: { href: '/commercial-storage-near-me', title: 'commercial storage near me' },
  AFFORDABLE_STORAGE_RATES: { href: '/affordable-storage-rates', title: 'affordable storage rates' },
  FREE_STORAGE_PLACES_NEAR_ME: { href: '/free-storage-places-near-me', title: 'free storage places near me' },
  SHIP_FROM_STORAGE_SERVICES: { href: '/ship-from-storage-services', title: 'ship from storage services' },
  TEMPORARY_STORAGE_SOLUTIONS: { href: '/temporary-storage-solutions', title: 'temporary storage solutions' },
  PERSONAL_STORAGE_SOLUTIONS: { href: '/personal-storage-solutions', title: 'personal storage solutions' },
  PICKUP_AND_STORE_SERVICES: { href: '/pickup-and-store-services', title: 'pickup and store services' },
  AFFORDABLE_STUDENT_STORAGE_NEAR_ME: {
    href: '/affordable-student-storage-near-me',
    title: 'affordable student storage near me'
  },
  GOLF_BAGS_STORAGES_SERVICES_NEAR_ME: {
    href: '/golf-bags-storages-services-near-me',
    title: 'golf bags storages services near me'
  },
  SKI_EQUIPMENT_AND_GEAR_STORAGES: {
    href: '/ski-equipment-and-gear-storages',
    title: 'ski equipment and gear storages'
  },
  CHRISTMAS_ITEM_STORAGE_NEAR_ME: { href: '/christmas-item-storage-near-me', title: 'christmas item storage near me' },
  STORAGE_SHIPPING_SERVICES_AT_UNIVERSITIES: {
    href: '/storage-shipping-services-at-universities',
    title: 'storage shipping services at universities'
  },
  DORM_ROOM_STORAGE_SERVICES: { href: '/dorm-room-storage-services', title: 'dorm room storage services' },
  OFFICE_EQUIPMENT_STORAGE_SERVICES: {
    href: '/office-equipment-storage-services',
    title: 'office equipment storage services'
  },
  MOVING_AND_STORAGE_SERVICES: { href: '/moving-and-storage-services', title: 'moving and storage services' },
  LUGGAGE_SHIPPING_SERVICES: { href: '/luggage-shipping-services', title: 'luggage shipping services' },
  MOVING_BOXES_SHIPPING_SERVICES: { href: '/moving-boxes-shipping-services', title: 'moving boxes shipping services' },
  INTERNATIONAL_LUGGAGE_SHIPPING: { href: '/international-luggage-shipping', title: 'international luggage shipping' },
  DHL_LUGGAGE_SHIPPING: { href: '/dhl-luggage-shipping', title: 'dhl luggage shipping' },
  FEDEX_LUGGAGE_SHIPPING: { href: '/fedex-luggage-shipping', title: 'fedex luggage shipping' },
  UPS_LUGGAGE_SHIPPING: { href: '/ups-luggage-shipping', title: 'ups luggage shipping' },
  STORAGE_AND_SHIPPING_COMPANIES: { href: '/storage-and-shipping-companies', title: 'storage and shipping companies' },
  STORAGE_AND_INTERNATIONAL_SHIPPING: {
    href: '/storage-and-international-shipping',
    title: 'storage and international shipping'
  },
  SHIP_LUGGAGE_INTERNATIONALLY: { href: '/ship-luggage-internationally', title: 'ship luggage internationally' },
  CHEAPEST_WAY_TO_SHIP_LUGGAGE: { href: '/cheapest-way-to-ship-luggage', title: 'cheapest way to ship luggage' },
  LUGGAGE_DELIVERY_SERVICES: { href: '/luggage-delivery-services', title: 'luggage delivery services' },
  LUGGAGE_SHIPPING_COMPANIES: { href: '/luggage-shipping-companies', title: 'luggage shipping companies' },
  LUGGAGE_STORAGE_FOR_BUSINESS_TRAVELERS: {
    href: '/luggage-storage-for-business-travelers',
    title: 'luggage storage for business travelers'
  },
  RESIDENTIAL_STORAGE_AND_SHIPPING_SOLUTIONS: {
    href: '/residential-storage-and-shipping-solutions',
    title: 'residential storage and shipping solutions'
  },
  INTERNATIONAL_LUGGAGE_STORAGE_AND_SHIPPING: {
    href: '/international-luggage-storage-and-shipping',
    title: 'international luggage storage and shipping'
  },
  SHIP_LUGGAGE_TO_STORAGE_FACILITY: {
    href: '/ship-luggage-to-storage-facility',
    title: 'ship luggage to storage facility'
  },
  AFFORDABLE_COLLEGE_STORAGE_SOLUTIONS: {
    href: '/affordable-college-storage-solutions',
    title: 'affordable college storage solutions'
  },
  VACATION_LUGGAGE_STORAGE_AND_SHIPPING: {
    href: '/vacation-luggage-storage-and-shipping',
    title: 'vacation luggage storage and shipping'
  },
  SEASONAL_LUGGAGE_STORAGE_SOLUTIONS: {
    href: '/seasonal-luggage-storage-solutions',
    title: 'seasonal luggage storage solutions'
  }
}

type LandingPagesRoutesKeys = keyof typeof LANDING_PAGES_ROUTES_CONSTANT

export const LANDING_PAGES_ROUTES: Record<LandingPagesRoutesKeys, UrlType> = LANDING_PAGES_ROUTES_CONSTANT
