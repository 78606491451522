import { UrlType } from '@/v2/types'

/* All Visible Routes */
export const PUBLIC_MAIN_ROUTES_CONSTANT = {
  HOME: { href: '/', title: 'Home Page', priority: 1 },
  SITEMAP: { href: '/sas-url-list', title: 'Sitemap', priority: 0.9 },
  CONTACT: { href: '/contact', title: 'Contact Us' },
  TERMS_AND_CONDITIONS: { href: '/terms-and-conditions', title: 'Terms And Conditions' },
  PRIVACY_POLICY: { href: '/privacy-policy', title: 'Privacy Policy' },
  ABOUT_US: { href: '/about-us', title: 'About Us' },
  DROP_OFF_STORAGE_LOCATIONS: { href: '/drop-off-storage-locations', title: 'Drop Off Storage Locations' },
  DROP_OFF_LOCATION: { href: '/drop-off-location', title: 'Drop Off Location' },
  PICKUP_FROM_STORAGE: { href: '/pickup-from-storage', title: 'Pickup From Storage' },
  DOMESTIC_SHIPPING_SERVICES: { href: '/domestic-shipping-services', title: 'Domestic Shipping Services' },
  INTERNATIONAL_SHIPPING_SERVICES: {
    href: '/international-shipping-services',
    title: 'International Shipping Services'
  },
  HOW_SHIP_AND_STORAGE_WORKS: { href: '/how-ship-and-storage-works', title: 'How Ship And Storage Works' },
  SHIPPING_TO_STORAGE: { href: '/shipping-to-storage', title: 'Shipping To Storage' },
  COST_SAVING_TIPS_ON_SHIPPING: { href: '/cost-saving-tips-on-shipping', title: 'Cost Saving Tips On Shipping' },
  SKI_GEAR_STORAGE: { href: '/ski-gear-storage', title: 'Ski Gear Storage' },
  SKI_GEAR_SHIPPING: { href: '/ski-gear-shipping', title: 'Ski Gear Shipping' },
  GOLF_CLUB_STORAGE: { href: '/golf-club-storage', title: 'Golf Club Storage' },
  LUGGAGE_SHIPPING: { href: '/luggage-shipping', title: 'Luggage Shipping' },
  LUGGAGE_STORAGE: { href: '/luggage-storage', title: 'Luggage Storage' },
  SNOWBOARD_SHIPPING: { href: '/snowboard-shipping', title: 'Snowboard Shipping' },
  COLLEGE_STORAGE_SERVICES: { href: '/college-storage-services', title: 'College Storage Services' },
  MOVING_BOX_STORAGE: { href: '/moving-box-storage', title: 'Moving Box Storage' },
  MOVING_BOX_SHIPPING: { href: '/moving-box-shipping', title: 'Moving Box Shipping' },
  COLLEGE_STUDENT_SHIPPING: { href: '/college-student-shipping', title: 'College Student Shipping' },
  SUMMER_CAMP_SHIPPING: { href: '/summer-camp-shipping', title: 'Summer Camp Shipping' },
  HOW_STORAGE_SERVICES_WORKS: { href: '/how-storage-services-works', title: 'How Storage Services Work' },
  HOW_TO_ATTACH_SHIPPING_LABELS: { href: '/how-to-attach-shipping-labels', title: 'How To Attach Shipping Labels' },
  USE_CORRECT_PACKING_MATERIAL: { href: '/use-correct-packing-material', title: 'Use Correct Packing Material' },
  SHIPPING_WEIGHT_AND_SIZES: { href: '/shipping-weight-and-sizes', title: 'Shipping Weight And Sizes' },
  SHIPPING_INSURANCE: { href: '/shipping-insurance', title: 'Shipping Insurance' },
  WHAT_WE_SHIP: { href: '/what-we-ship', title: 'What We Ship' },
  WHAT_CANT_SHIP: { href: '/what-cant-ship', title: "What Can'T Ship ?" },
  SHIPPING_TIPS: { href: '/shipping-tips', title: 'Shipping Tips' },
  SNOWBOARD_STORAGE: { href: '/snowboard-storage', title: 'Snowboard Storage' },
  ORDER_PACKING_MATERIAL: { href: '/order-packing-material', title: 'Order Packing Material' },
  LUGGAGE_TRACKING: { href: '/luggage-tracking', title: 'Luggage Tracking', priority: 0.64 },
  LUGGAGE_AND_QUESTION: { href: '/luggage-and-question', title: 'Luggage And Question' },
  BOOK_STORAGE_FACILITY: { href: '/book-storage-facility', title: 'Book Storage Facility' },
  MOVING_STORAGE_SOLUTIONS: { href: '/moving-storage-solution', title: 'Moving Storage Solution' },
  RESIDENTIAL_STORAGE: { href: '/residential-storage', title: 'Residential Storage' },
  BUSINESS_STORAGE: { href: '/business-storage', title: 'Business Storage' },
  LUGGAGE_BOX_SHIPPING_SERVICES: { href: '/luggage-box-shipping-services', title: 'Luggage Box Shipping Services' }
} as const

type PublicPagesRoutesKeys = keyof typeof PUBLIC_MAIN_ROUTES_CONSTANT

export const PUBLIC_MAIN_ROUTES: Record<PublicPagesRoutesKeys, UrlType> = PUBLIC_MAIN_ROUTES_CONSTANT
