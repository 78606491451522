import PageLoader from '@/components/common/PageLoader'
import * as actionTypes from '@/store/constants/actionTypes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faList, faTimes } from '@fortawesome/free-solid-svg-icons'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import { connect } from 'react-redux'
import MenuItemDashboard from './MenuItemDashboard'

library.add(faList, faTimes)

const variants = {
  open: { opacity: 1, height: 'auto' },
  closed: { opacity: 0, height: 0 }
}

const MobileMenuDashboard = function (props) {
  let menuItems = props.menuItems.map((item, key) => {
    return <MenuItemDashboard key={key} item={item} />
  })

  let authInfo = props.Auth.authInfo

  return (
    <>
      {props.Auth.auth ? (
        <LazyMotion features={domAnimation}>
          <m.ul className='main-menu user-main-menu mobile-main-menu' animate='open' variants={variants}>
            <li className='profile text-center'>
              <>
                <span className='account-name'>
                  Hello <span className='text-secondary font-semibold'>{authInfo.first_name}</span>
                </span>
                <span className='account-number'>
                  Account: <span className='text-secondary font-semibold'>{authInfo.username}</span>
                </span>
              </>
            </li>
            {menuItems}
          </m.ul>
        </LazyMotion>
      ) : (
        <div className={'mx-auto mt-6'}>
          <PageLoader />
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    Auth: state.authReducer
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch({ type: actionTypes.USER_LOGOUT })
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuDashboard)
