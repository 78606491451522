import Configs from '@/configs'
import { generateStructuredData } from '@/v2/helpers/structured-data-generator'
import { FaqQuestionType } from '@/v2/types'
import { OGImageType, OpenGraphMetadataType } from '@/v2/types/meta'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

const BASE_URL = Configs.appURL || ''

// const metaImgPath = `${BASE_URL}/social-thumbnail.jpg`
const metaTitle = 'Smart Storage & Worldwide Shipping'
const metaContent =
  'Store 1 Small box To 100 Large boxes Storage with Free Insurance up to $250 and 6 Months Free Storage. Get Smart Storage Services And ship anytime, anywhere!'
const metaKeywords = `Smart storage, Ship and storage, Storage and shipping, Storage facilities in New York, Storage facilities in Delaware, Storage and shipping services, Affordable storage, Cheapest storage, International storage and shipping, Storage and shipping services USA, Storage ez, Ez storage, Ez storage near me, Easy storage, Easy storage solutions, Easy storage near me, My storage near me, Self storage, Self storage near me, Self storage units near me, self storage facilities near me`

type additionalDataType = {
  faq?: FaqQuestionType[]
}

interface MetaProps {
  title?: string
  keywords?: string
  description?: string
  searchImages?: OGImageType[]
  favIcon?: string
  noIndex?: boolean
  noFollow?: boolean
  openGraph?: OpenGraphMetadataType
  structuredData?: unknown
  additionalData?: additionalDataType
}

const openGraphConstants: OpenGraphMetadataType = {
  locale: 'en_US',
  type: 'website',
  title: metaTitle,
  description: metaContent
}
const Meta: React.FC<MetaProps> = ({
  title,
  keywords,
  description,
  openGraph = openGraphConstants,
  structuredData,
  additionalData,
  searchImages = [{ url: '/social-thumbnail.jpg' }],
  favIcon = `${BASE_URL}/favicons/favicon.ico`,
  noIndex,
  noFollow
}) => {
  const generatedTitle = title ?? metaTitle
  const generatedContent = description ?? metaContent
  const generatedKeywords = keywords ?? metaKeywords

  const router = useRouter()
  const asPath = router.asPath === '/' ? '' : router.asPath
  const canonicalUrl = `${BASE_URL}${asPath}`

  const imageUrls = searchImages.map(image => {
    const url = typeof image.url === 'string' ? BASE_URL.concat(image.url) : image.url.toString()

    return url

    // return {
    //       '@type': 'ImageObject',
    //       url,
    //       // secure_url: url,
    //       width: image.width ?? 92,
    //       height: image.height ?? 92
    //       // alt: image.alt ?? `${image.url} search image in Ship and Storage`
    //     }
  })

  // Structured Data for Local Business
  const structuredDataObject =
    structuredData ??
    generateStructuredData({ imageUrls, description: generatedContent, url: canonicalUrl, ...additionalData })

  const ogImages: OGImageType[] = openGraph.images ?? [
    { url: '/social-thumbnail.jpg', type: 'image/jpg', alt: 'ship and storage (sas) logo' }
  ]

  const isNotIndexed = Boolean(noIndex)
  const isNotFollowed = Boolean(noFollow)

  const robotsContent: string = (isNotIndexed ? 'noindex' : 'index').concat(isNotFollowed ? ', nofollow' : ', follow')

  console.log('xxx', searchImages)

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='robots' content={robotsContent} />
        <meta name='language' content='English' />

        <title>{`${generatedTitle} | ${Configs.APP_NAME}`}</title>
        <meta name='title' content={generatedTitle} />
        <meta name='description' content={generatedContent} />
        <meta name='keywords' content={`${generatedKeywords}`} />
        <meta name='author' content={Configs.APP_NAME ?? 'ShipAndStorage'} />

        <link rel='icon' href={`${favIcon}`} />
        <link rel='canonical' href={canonicalUrl} />
        <link rel='apple-touch-icon' sizes='180x180' href={`${BASE_URL}/favicons/apple-touch-icon.png`} />
        <link rel='icon' type='image/png' sizes='32x32' href={`${BASE_URL}/favicons/favicon-32x32.png`} />
        <link rel='icon' type='image/png' sizes='16x16' href={`${BASE_URL}/favicons/favicon-16x16.png`} />
        <link rel='manifest' href={`${BASE_URL}/favicons/site.webmanifest`} />

        {/*Open graph media Tags*/}

        <meta property='og:title' content={openGraph.title ?? generatedTitle} />
        <meta property='og:description' content={openGraph.description ?? generatedContent} />
        <meta property='og:url' content={canonicalUrl} />
        <meta property='og:type' content={openGraph.type ?? 'website'} />
        <meta property='og:site_name' content={Configs.APP_NAME} />

        {/*Twitter META Tags*/}
        <meta name='twitter:title' content={openGraph.title ?? generatedTitle} />
        <meta name='twitter:description' content={openGraph.description ?? generatedContent} />
        <meta name='twitter:card' content={'summary_large_image'} />
        <meta name='twitter:site' content={`@${Configs.APP_NAME}`} />

        {ogImages.map((image, index) => {
          const url = typeof image.url === 'string' ? BASE_URL.concat(image.url) : image.url.toString()
          // const secure_url = image?.secureUrl
          //   ? typeof image?.secureUrl === 'string'
          //     ? BASE_URL.concat(image?.secureUrl)
          //     : image?.secureUrl.toString()
          //   : url

          return (
            <Fragment key={index}>
              {/* <meta property='og:image:url' content={url} />
              <meta property='og:image:secure_url' content={secure_url} /> */}
              <meta property='og:image:type' content={image?.type ?? 'image/jpg'} />
              <meta property='og:image:width' content={image?.width?.toString() ?? '1200'} />
              <meta property='og:image:height' content={image?.height?.toString() ?? '630'} />
              <meta
                property='og:image:alt'
                content={image?.alt ?? `${canonicalUrl} open graph service preview in Ship and Storage`}
              />
              <meta property='og:image' content={url} />

              {/*Twitter META image Tag*/}

              <meta name='twitter:image' content={url} />
              <meta
                name='twitter:image:alt'
                content={image?.alt ?? `${canonicalUrl} open graph service preview in Ship and Storage`}
              />
            </Fragment>
          )
        })}

        {openGraph?.videos?.map((video, index) => {
          const url = typeof video.url === 'string' ? BASE_URL.concat(video.url) : video.url.toString()
          const secure_url = video.secureUrl
            ? typeof video.secureUrl === 'string'
              ? BASE_URL.concat(video.secureUrl)
              : video.secureUrl.toString()
            : url

          return (
            <Fragment key={index}>
              <meta property='og:video:url' content={url} />
              <meta property='og:video:secure_url' content={secure_url} />
              <meta property='og:video:type' content={video.type ?? 'video/mp4'} />
              {video.width && <meta property='og:video:width' content={video.width.toString()} />}
              {video.height && <meta property='og:video:height' content={video.height.toString()} />}
            </Fragment>
          )
        })}
        {openGraph?.audio?.map((audio, index) => {
          const url = typeof audio.url === 'string' ? BASE_URL.concat(audio.url) : audio.url.toString()
          const secure_url = audio.secureUrl
            ? typeof audio.secureUrl === 'string'
              ? BASE_URL.concat(audio.secureUrl)
              : audio.secureUrl.toString()
            : url

          return (
            <Fragment key={index}>
              <meta property='og:audio:url' content={url} />
              <meta property='og:audio:secure_url' content={secure_url} />
              <meta property='og:audio:type' content={audio.type ?? 'audio/mpeg'} />
            </Fragment>
          )
        })}

        <meta property='og:determiner' content={openGraph.determiner ?? ''} />
        <meta property='og:locale' content={openGraph.locale ?? 'en_US'} />
        {openGraph?.alternateLocales?.map((alternateLocale, index) => (
          <meta key={index} property='og:locale:alternate' content={alternateLocale} />
        ))}

        {/*Mobile META Tags*/}
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='application-name' content='ShipAndStorage' />
        <meta name='apple-mobile-web-app-title' content='ShipAndStorage' />
        <meta name='apple-mobile-web-app-status-bar-style' content='#FF6D0A' />

        {/* Structured Data */}
        <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredDataObject) }} />
      </Head>
    </>
  )
}

export default Meta
